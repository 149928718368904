body {
  margin: 0;
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.swagger-editor {
  font-size: 1.3em;
  margin-left: auto;
  margin-right: auto;
}

.container {
  height: 100%;
  max-width: 880px;
  margin-left: auto;
  margin-right: 200px;
}

#editor-wrapper * {
  height: 100%;
  font-family: Monaco, Menlo, 'Ubuntu Mono', Consolas, source-code-pro, monospace !important;
}

.Pane2 {
  overflow-y: scroll;
}

.SplitPane {
  position: relative !important;
}

.container {
  max-width: 100%;
}

.swagger-editor #ace-editor {
  height: 80vh !important;
  width: 105%;
}
.swagger-editor .SplitPane {
  height: 80vh !important;
  width: 105%;
}
