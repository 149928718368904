.iconDiv {
  position: fixed;
  display: inline-flex;
  bottom: 0;
  right: 0;
  height: 50px;
  width: 50px;
  margin: 32px;
  text-align: right;
  border-radius: 50%;
  border-style: solid;
  border-color: #3d659d;
  background-color: #f7f7f7;
  border-width: 2px;
  white-space: nowrap;
  overflow: hidden;
  transition:
    width 300ms ease-in-out 0s,
    background-color 300ms linear 200ms;
  justify-content: flex-start;
  z-index: 100;
}
.iconDiv:hover,
.iconDiv:focus-visible {
  width: 250px;
  transition:
    width 300ms ease-in-out 0s,
    background-color 100ms linear 0s;
  border-radius: 47px;
  border-width: 2px;
  border-color: #3d659d;
}

.iconText {
  margin-left: 0.5rem;
  color: #3d659d;
}

.iconClose {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  color: #3d659d;
  margin-right: 10px;
}

.chatButton:hover .iconClose {
  display: block;
}
