.SwaggerComponent table,
.SwaggerComponent td,
.SwaggerComponent th {
  border: 1px solid;
}
.SwaggerComponent td {
  padding: 30px;
  text-align: center;
}
.SwaggerComponent table {
  width: 100%;
  border-collapse: collapse;
}
.SwaggerComponent td {
  padding: 15px;
  text-align: left;
}
